<template>
  <div class="exclusive">
    <!-- 头部 -->
    <header>
      <span class="tips">!</span>
      <span>长按二维码图片保存到手机相册</span>
    </header>
    <div class="content">
      <main ref="card">
        <div class="main-top">
          <div class="head-portrait">
            <img :src="userObj.employeeAvatar" alt="" />
          </div>
          <div class="brief-introduction">
            <p class="name">{{ userObj.employeeName }}</p>
            <p class="company">{{ userObj.employeeCorp }}</p>
          </div>
        </div>
        <!-- 二维码 -->
        <div class="qrcode">
          <img :src="userObj.qrcodeUrl" alt="" />
        </div>
        <p>扫一扫上面的二维码图案，加我企业微信</p>
      </main>
    </div>
    <!-- 邀请记录 -->
    <div class="invite">
      <div class="title">邀请成功记录</div>
      <Empty
        v-if="inviteList.length <= 0"
        class="custom-image"
        :image="require('../../static/images/quick-reply_radar_no-data.png')"
      />
      <van-list
        v-model="loading"
        :finished="finished"
        @load="getInviteList"
        finished-text="没有更多了"
        offset="20"
        :immediate-check="fasle"
        v-else
      >
        <div
          class="customer-item"
          v-for="(item, index) in inviteList"
          :key="index"
        >
          <div class="customer-header">
            <img :src="item.newContactAvatar || ''" alt="" />
          </div>
          <p class="name">{{ item.newContactName }}<span class="weix">@微信</span></p>
          <p class="date">{{ item.inviteAt }}</p>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>
import { getCIndex, getinviteRecord } from "@/api/exclusive";
import { Empty } from 'vant'
export default {
  components: {
    Empty
  },
  data () {
    return {
      userObj: {
        employeeAvatar: '',
        employeeCorp: '',
        employeeName: '',
        qrcodeUrl: '',
        id: '' // 二维码id
      },
      pageData: {
        page: 1,
        perPage: 10
      },
      inviteList: [],
      finished: false,
      loading: false
    }
  },
  async created () {
    await this.getIndex()
    this.getInviteList()
  },
  mounted () {
    if (this.$route.query.name) {
      document.title = this.$route.query.name + '的专属二维码'
    }
  },
  methods: {
    async getIndex () {
      const res = await getCIndex({ id: this.$route.query.qrId })
      if (res.code == 200) {
        this.userObj = res.data
      }
    },
    getInviteList () {
      if (this.inviteList.length <= 0) this.pageData.page = 1
      const data = {
        codeId: this.userObj.id,
        ...this.pageData
      }
      console.log(data)
      getinviteRecord(data).then(res => {
        if (res.data.list.length > 0) {
          this.inviteList = this.inviteList.concat(res.data.list)
          this.loading = false
        } else {
          this.loading = false
          this.finished = true
        }
        if (res.data.list.length < 10) {
          this.finished = true
        }
      })
      this.pageData.page += 1
      // this.inviteList.push(
      //   {
      //     newContactName: '张1',
      //     newContactAvatar: 'https://citichdimg.urthink.com/zxpic/ZxApplet/head12.png',
      //     inviteAt: '2021-04-19  18:00:47'
      //   },
      //   {
      //     newContactName: '张2',
      //     newContactAvatar: 'https://citichdimg.urthink.com/zxpic/ZxApplet/head12.png',
      //     inviteAt: '2021-04-19  18:00:47'
      //   },
      //   {
      //     newContactName: '张3',
      //     newContactAvatar: 'https://citichdimg.urthink.com/zxpic/ZxApplet/head12.png',
      //     inviteAt: '2021-04-19  18:00:47'
      //   },
      //   {
      //     newContactName: '张4',
      //     newContactAvatar: 'https://citichdimg.urthink.com/zxpic/ZxApplet/head12.png',
      //     inviteAt: '2021-04-19  18:00:47'
      //   },
      //   {
      //     newContactName: '张5',
      //     newContactAvatar: 'https://citichdimg.urthink.com/zxpic/ZxApplet/head12.png',
      //     inviteAt: '2021-04-19  18:00:47'
      //   },
      //   {
      //     newContactName: '张6',
      //     newContactAvatar: 'https://citichdimg.urthink.com/zxpic/ZxApplet/head12.png',
      //     inviteAt: '2021-04-19  18:00:47'
      //   },
      //   {
      //     newContactName: '张7',
      //     newContactAvatar: 'https://citichdimg.urthink.com/zxpic/ZxApplet/head12.png',
      //     inviteAt: '2021-04-19  18:00:47'
      //   },
      //   {
      //     newContactName: '张8',
      //     newContactAvatar: 'https://citichdimg.urthink.com/zxpic/ZxApplet/head12.png',
      //     inviteAt: '2021-04-19  18:00:47'
      //   },
      //   {
      //     newContactName: '张9',
      //     newContactAvatar: 'https://citichdimg.urthink.com/zxpic/ZxApplet/head12.png',
      //     inviteAt: '2021-04-19  18:00:47'
      //   },
      //   {
      //     newContactName: '张10',
      //     newContactAvatar: 'https://citichdimg.urthink.com/zxpic/ZxApplet/head12.png',
      //     inviteAt: '2021-04-19  18:00:47'
      //   }
      // )
    }
  }
}
</script>
<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
.exclusive {
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  font-size: 16px;
  color: #666666;
  background-color: #efeff4;
  height: 100%;
  padding-top: 10px;
  header {
    width: 335px;
    height: 44px;
    background: #fffbe3;
    border-radius: 1px;
    border: 1px solid #fad7a2;
    // margin-top: 20px;
    margin: 0px auto;
    display: flex;
    align-items: center;
    .tips {
      display: inline-block;
      width: 22px;
      line-height: 22px;
      text-align: center;
      background: #ffb160;
      font-weight: 500;
      color: #ffffff;
      border-radius: 50%;
      margin: 0 11px;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    main {
      width: 300px;
      // height: 364px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 18px 37px 20px 37px;
      padding: 20px;

      background-color: #fff;
      border-radius: 2px;
      .main-top {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .head-portrait {
          width: 60px;
          height: 60px;
          background: #d8d8d8;
          border-radius: 4px;
          img {
            width: 100%;
          }
        }
        .brief-introduction {
          margin-left: 14px;
          font-weight: 500;
          .name {
            font-size: 20px;
            color: #333333;
            line-height: 28px;
          }
          .company {
            font-size: 14px;
            color: #999999;
            line-height: 20px;
            margin-top: 8px;
          }
        }
      }
      .qrcode {
        width: 196px;
        height: 195px;
        background-color: #d8d8d8;
        margin: 30px 30px 20px 30px;
        img {
          width: 100%;
        }
      }
      p {
        font-size: 14px;
        font-weight: 500;
        color: #999999;
        line-height: 20px;
      }
    }
  }
  .invite {
    margin: 18px 18px 10px 18px;
    padding: 10px;
    .title {
      margin-bottom: 10px;
    }
    .customer-item {
      display: flex;
      align-items: center;
      font-weight: 500;
      color: #333333;
      margin-bottom: 20px;
      .customer-header {
        width: 50px;
        height: 50px;
        border-radius: 4px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .name {
        margin-left: 10px;
        font-weight: 500;
        .weix {
          font-size: 16px;
          color: #95f204;
          line-height: 22px;
          margin-left: 10px;
        }
      }
      .date {
        font-size: 14px;
        color: #999999;
        line-height: 20px;
        margin-top: 5px;
        margin-left: auto
      }
    }
  }
}
</style>
